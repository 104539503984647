<template>
    <div>
        <div class="treeviewdiv">
            <div class="nodetext">
                <span class="mr-3 treeName">{{data.name}}</span>
                <span class="treeUrl float-right text-muted">{{data.url}}</span>
            </div>

     </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                data: {}
            };
        }
    };

</script>
